<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div style="width: 100%;">
    <card v-if="view == 'profile'">
      <h5 slot="header" class="title">Edit Profile</h5>
      <div class="row">
        <base-alert v-if="success" type="success">
          <span class="text-center">Profile successfully updated</span>
        </base-alert>
        <div class="col-md-12 px-md-2">
          <base-input label="Username" placeholder="Username" v-model="model.username">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 px-md-2">
          <base-input label="Email address" type="email" placeholder="Email address" v-model="model.email">
          </base-input>
        </div>
        <div class="col-md-12 px-md-2">
          <base-input label="Company Name" placeholder="Company Name" v-model="model.company">
          </base-input>
        </div>
        <div class="col-md-12 px-md-2">
          <base-input label="Website" placeholder="Website" v-model="model.website">
          </base-input>
        </div>
        <div class="col-md-12 px-md-2">
          <base-input label="Skipe" placeholder="Skipe" v-model="model.skipe">
          </base-input>
        </div>
      </div>

      <h5 class=" text-danger mt-4" style="font-size: 1em;" v-if="errors.profile">{{  errors.profile }}</h5>

      <base-button @click="updateUser" slot="footer" type="primary" fill>Save</base-button>
    </card>
    <card v-if="view == 'password'">
      <h5 slot="header" class="title">Edit Password</h5>
      <div class="row">
        <div class="col-md-12 px-md-2">
          <base-input label="Password" type="password" placeholder="Password" v-model="model.password">
          </base-input>
        </div>
        <div class="col-md-12 px-md-2">
          <base-input label="Confirm Password" type="password" placeholder="Confirm Password"
            v-model="model.confirmPassword">
          </base-input>
        </div>
      </div>

      <h5 class=" text-danger mt-4" style="font-size: 1em;" v-if="errors.password">{{  errors.password }}</h5>



      <base-button @click="updateUser('password')" slot="footer" type="primary" fill>Save</base-button>
    </card>
  </div>

</template>
<script>

import { BaseAlert } from '@/components';
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  components: {
    BaseAlert,
    VSwatches
  },
  created() {
    console.log('-----edtipor', this.model, this.view);
  },
  data: function () {
    return {

    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    success: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
    },
    formLoading: {
      type: Boolean,
      default: false,
    },
    updateUser: {
      type: Function,
      default: () => { }
    },
    onChange: {
      type: Function,
      default: () => { }
    },
    errors: {
      type: Object
    }
  },
  methods: {
    // onChange(key, val) {
    //   this.onValueChange(key, val)
    // }
  },
  watch: {
    model() {
      console.log('-----model', this.model);
    }
  }
};
</script>
<style>

</style>
