<template>
  <div class="row">
    <div class="col-12">
      <card :title="table1.title">
        <div class="row justify-content-between px-4">
            <div>
              <span style="font-size: 17px; font-weight: 500; letter-spacing: 0.6px;" :style="{color: isDark ? 'white' : 'black'}" >
                Roblox Stock: <span style="font-weight: bold;" :style="{color: this.user?.robux ? 'green' : 'red'}">{{ this.user?.robux || 0 }} Rbx</span></span>
            </div>
            <div>
              <base-button @click="openAddRewardModel" class="mr-3"  type="primary" fill>New Reward</base-button>
              <base-button @click="openRewardSite"  type="primary" fill>Visit Rewards Page</base-button>
          </div>

        </div>

        <add-reward
          :show="showAddRewardModal"
          :close="closeAddRewardModel"
          :save="addReward"
          :model="addRewardModel"
          :loading="loads?.createReward"
          :onSelectChange="onSelectRewardType"
        >

        </add-reward>
        

        <div v-if="selectedItems.length > 0">
          <base-button @click="updateReward(selectedItems, isMajoritySelectedActive)" size="sm"  :type="isMajoritySelectedActive ? 'danger' : 'primary'" fill>{{ isMajoritySelectedActive ? "Disable All" : "Enable all" }}</base-button>
        </div>

        <div class="table-responsive">
          <reward-table
            :currentPage="currentPage"
            :perPage="perPage"
            :switchStatus="updateReward"
            :edit="navigateToReward"
            :remove="deleteReward"
            :data="table1.data" 
            :columns="table1.columns"
            thead-classes="text-primary"
            :onSelect="onSelect"
            :selectedItems="selectedItems"
            :loadingItem="loadingItem"
            :loading="loading"
          >
          </reward-table>
          <div v-if="table1.data.length >= 1 && !loading">
            <pagination
            :totalItems="table1.data.length"
              :totalPages="totalPages"
              :currentPage="currentPage"
              :onSelect="selectPage"
              :perPage="perPage"
              :onPerPageChange="onPerPageChange"
            ></pagination>
        </div>

        <div
              v-else-if="loading"
              style="height: 40px; width: 100%;"
              class="row justify-content-center align-items-center"
            >
              <clip-loader  color="#C153EC" size="40px" :loading="loading" ></clip-loader>
        </div>
        <div v-else class="row justify-content-center white text-white">
          <span>No results found</span>
        </div>
        </div>
      </card>
    </div>

  </div>
</template>
<script>

import { RewardTable } from "@/components";
import { BaseSelect, BaseButton } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from 'vuex';
import Countries from '../assets/data/countries.json'
import AddReward from "./Rewards/AddReward.vue";

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

const tableColumns = ["Name", "Image", "Total Options", "Status", "Created At", "control"];

export default {
  components: {
    RewardTable,
    BaseSelect,
    Pagination,
    BaseButton,
    AddReward,
    ClipLoader
  },
  async mounted() {
    console.log('---rewards', this.rewards);
    let countries = Countries.map(x => {
      return {
        title: x.name,
        value: x.code
      }
    })
    this.countryFilter.options = [...this.countryFilter.options, ...countries]
   await  this.$store.dispatch("getUser")
    this.getRewards()
  },
  updated() {
    console.log('---this.rewards', this.rewards);
  },
  data() {
    return {
      table1: {
        title: "Rewards",
        columns: [...tableColumns],
        data: []
      },
      countryFilter: {
        label: "Country",
        name: "country",
        options: [{ title: "ALL", value: "all" }],
      },
      deviceFilter: {
        label: "Device",
        name: "device",
        options: [{ title: "ALL", value: "all" }, { title: "Web", value: "Web" }, { title: "Android", value: "Android" }, { title: "Iphone", value: "iPhone" }],
      },
      country: "all",
      device: "all",
      currentPage: 1,
      perPage: 10,
      selectedItems: [],
      showAddRewardModal: false,
      addRewardModel: {
        name: null,
        image: null,
        minimum_withdraw: null,
        exchange_rate: null,
        type: "all"
      },
      loading: false,
      loadingItem: null,
      
    };
  },
  computed: {
    totalPages() {
      return Math.round(this.rewards.length / this.perPage)
    },
    isMajoritySelectedActive() {
      const item = this.rewards.find(x => x.id == this.selectedItems[0])
      if (item && item.active)return true;
      return false
    },
    isDark() {
      return localStorage.getItem('theme') === 'black'
    },
    ...mapGetters([
      'rewards',
      'loads',
      'user'
    ])
  },
  methods: {
    getRewards: async function () {
      this.loading = true;
      await this.$store.dispatch("getRewards", {country: this.country, device: this.device, advertiser_id: this.user?._id});
      this.table1.data = [...this.rewards]
      this.loading = false;
      this.currentPage = 1
    },
    addReward: async function() {
      const {image, name, exchange_rate, minimum_withdraw} = this.addRewardModel;
      // console.log('----image', {image, name});
      if (!image || !name || !exchange_rate || !minimum_withdraw) {
        // show error message
        return;
      }
      this.loading = true;
      await this.$store.dispatch("createReward", this.addRewardModel);
      await this.getRewards()
      this.closeAddRewardModel()
      this.loading = false;
      this.notifyVue(true, "Successfully created")
    },
    updateReward: async function(ids, status) {
      console.log('----ids', ids, status);
      this.loadingItem = {active: ids[0]};
      await this.$store.dispatch("updateReward", {active: !status, ids});
      // await this.getRewards()
      this.loadingItem = null;
      this.notifyVue(true, "Successfully updated")
    },
    deleteReward: async function(id) {
      this.loadingItem = {delete: id};
      await this.$store.dispatch("deleteReward", id);
      // await this.getRewards()
     this.loadingItem = null;
     this.notifyVue(true, "Successfully removed")

    },
    onFilterChange: function(key, val) {
      this[key] = val;
      this.getRewards()
    },
    selectPage: function (page) {
      if (page > this.totalPages || page < 1) return;
      this.currentPage = page
    },
    onSelect: function (ids, value) {
      if (value) {
        this.selectedItems = [...new Set(this.selectedItems.concat(ids))]
      } else {
        this.selectedItems = []
      }
    },
    openAddRewardModel: function () {
      console.log('---this.showAddRewardModal', this.showAddRewardModal);
      this.showAddRewardModal = true;
    },
    closeAddRewardModel: function () {
      this.showAddRewardModal = false;
    },
    navigateToReward: function (id) {
      this.$router.replace(`/reward/${id}`)
    },
    notifyVue(success, message) {
      this.$notify({
          message: message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: success ? "success" : "danger",
          timeout: 5000
        });
    },
    onPerPageChange: function (val) {
      console.log('-onPerPageChange', val)  
      if (!isNaN(val)) {
        this.perPage = val;
        this.currentPage = 1
      } 
    },
    onSelectRewardType: function (key, val) {
      console.log('-onSelectRewardType', key, val)  
      this.addRewardModel[key] = val
    },
    openRewardSite: function () {
      window.open(`https://rewards.rbxadder.com/#/rewards/${this.user?._id}`, '_blank')
    }
  },
  watch: {
    rewards(val) {
      this.table1.data = [...val]
    }
  }

};
</script>
<style>

</style>
