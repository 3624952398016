<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="row">
    <modal :show="show" id="searchModal" :centered="false" :show-close="true">
      <div
        class="row px-md-2"
        :style="{ 'background-color': theme == 'black' ? '#28293D' : 'white' }"
      >
        <h4 style="font-weight: bold; padding-left: 15px">New Reward</h4>
        <div class="col-md-12">
          <base-input
            label="Reward Name"
            type="text"
            placeholder="Name"
            v-model="model.name"
          >
          </base-input>
        </div>
        <div class="col-md-12">
          <base-input
            label="Image URL"
            placeholder="Image URL"
            v-model="model.image"
          >
          </base-input>
        </div>
        <div class="col-md-12">
          <base-input
            label="Minimum To Withdraw"
            placeholder="Minimum To Withdraw"
            v-model="model.minimum_withdraw"
          >
          </base-input>
        </div>
        <div class="col-md-12">
          <base-input
            label="Exchange Rate"
            placeholder="Exchange Rate"
            v-model="model.exchange_rate"
          >
          </base-input>
        </div>
        <div class="col-md-12">
            <base-select
          :onChange="(val) => onSelectChange('type', val)"
          :value="model.type"
          label="Type"
          class="pr-2  w-full"
          :options="typeOptions"
          :default="model.type"
        ></base-select>
        </div>

        <div class="px-3 mt-4">
          <base-button
            class="mr-3"
            :class="{ 'button-loading': loading }"
            :disabled="!model.name || !model.image || loading || !model.exchange_rate || !model.minimum_withdraw"
            @click="save"
            slot="footer"
            type="primary"
            fill
          >
            <clip-loader
              v-if="loading"
              color="white"
              size="25px"
              :loading="loading"
            ></clip-loader>
            <span v-else="">Save</span>
          </base-button>

          <base-button
            :disabled="loading"
            @click="close"
            slot="footer"
            type="danger"
            fill
            >Cancel</base-button
          >
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";

import { BaseAlert } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { BaseSelect } from "@/components";

export default {
  components: {
    BaseAlert,
    Modal,
    ClipLoader,
    BaseSelect
  },
  created() {
    console.log("-----edtipor", this.model, this.view);
  },
  data() {
    return {
      theme: localStorage.getItem("theme"),
      typeOptions: [
        { title: "Withdraw all funds", value: "all" },
        { title: "Choose from options", value: "multiple" },
      ],
    };
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Function,
    },
    close: {
      type: Function,
    },
    loading: Boolean,
    onSelectChange: Function,
  },
};
</script>
<style></style>
