<template>
  <div class="row">
    <div class="col-12">
      <card :title="table1.title">
        <!-- <div class="row justify-content-end px-4">
          <div class="row col-10 justify-content-end">
            <base-select :onChange="(val) => onFilterChange('country', val)" :model="country" :label="countryFilter.label"
              class="mr-2 w-25 w-full" :options="countryFilter.options" :default="country"></base-select>
            <base-select :onChange="(val) => onFilterChange('device', val)" :model="device" :label="deviceFilter.label"
              class="mr-2 w-25 w-full" :options="deviceFilter.options" :default="device"></base-select>

          </div>
        </div> -->

        <div v-if="selectedItems.length > 0">
          <base-button @click="updateConversion(selectedItems, isMajoritySelectedActive)" size="sm"  :type="isMajoritySelectedActive ? 'danger' : 'primary'" fill>{{ isMajoritySelectedActive ? "Disable All" : "Enable all" }}</base-button>
        </div>

        <div class="table-responsive">
          <conversion-table
            :currentPage="currentPage"
            :perPage="perPage"
            :data="table1.data" 
            :columns="table1.columns"
            thead-classes="text-primary"
            :onSelect="onSelect"
            :selectedItems="selectedItems"
            :sendCallback="sendCallback"
            :loadings="loadings"
            :updateConversion="updateConversion"
            
          >
          </conversion-table>
          <div v-if="table1?.data?.length > 0">
            <pagination
              :totalItems="table1.data.length"
              :totalPages="totalPages"
              :currentPage="currentPage"
              :onSelect="selectPage"
              :perPage="perPage"
              :onPerPageChange="onPerPageChange"
            ></pagination>
        </div>
        <div v-else class="row justify-content-center">
          <span>No results found</span>
        </div>
        </div>
      </card>
    </div>

  </div>
</template>
<script>

import { ConversionTable } from "@/components";
import { BaseSelect } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from 'vuex';
import Countries from '../../assets/data/countries.json'

const tableColumns = [ "id", "user", "Offer", "Payout", "country","Status", "callback status", "control"];

export default {
  components: {
    ConversionTable,
    BaseSelect,
    Pagination
  },
  mounted() {
    const countries = Countries.map(x => {
      return {
        title: x.name,
        value: x.code
      }
    })
    this.countryFilter.options = [...this.countryFilter.options, ...countries]
    this.getConversions()
  },
  props: {
    user: {
      type: Object
    }
  },
  updated() {
  },
  data() {
    return {
      table1: {
        title: "Conversions",
        columns: [...tableColumns],
        data: []
      },
      countryFilter: {
        label: "Country",
        name: "country",
        options: [{ title: "ALL", value: "all" }],
      },
      deviceFilter: {
        label: "Device",
        name: "device",
        options: [{ title: "ALL", value: "all" }, { title: "Web", value: "Web" }, { title: "Android", value: "Android" }, { title: "Iphone", value: "iPhone" }],
      },
      country: "all",
      device: "all",
      currentPage: 1,
      perPage: 10,
      selectedItems: [],
      loadings: null

      
    };
  },
  computed: {
    totalPages() {
      return Math.round(this.conversions.length / this.perPage)
    },
    isMajoritySelectedActive() {
      const item = this.conversions.find(x => x.id == this.selectedItems[0])
      if (item && item.active)return true;
      return false
    },
    ...mapGetters([
      'conversions',
      'visitor'
    ])
  },
  methods: {
    getConversions: async function () {
      this.loading = true;
      console.log('---this.getconversions', this.visitor);
      await this.$store.dispatch("getConversions", {user_id: this.visitor?.user_id});
      // await this.$store.dispatch("getConversions", {country: this.country, device: this.device});
      this.table1.data = [...this.conversions]
      this.loading = false;
      this.currentPage = 1
      console.log('----this.conversions', this.conversions);
    },
    sendCallback: async function(id) {
      this.loadings = {callback: id};
      const result = await this.$store.dispatch("sendCallback", id);
      // await this.getConversions()
      this.loadings = null;
      this.notifyVue(result?.success, result?.message);
    },
    updateConversion: async function(id, payload) {
      this.loadings = {update: id};
      const result = await this.$store.dispatch("updateConversion", {ids: [id], status: payload});
      // await this.getConversions()
      this.loadings = null;
      this.notifyVue(result?.success, result?.message);
    },
    selectPage: function (page) {
      if (page > this.totalPages || page < 1) return;
      this.currentPage = page
    },
    onSelect: function (ids, value) {
      if (value) {
        this.selectedItems = [...new Set(this.selectedItems.concat(ids))]
      } else {
        this.selectedItems = []
      }
    },
    onPerPageChange: function (val) {
      console.log('-onPerPageChange', val)  
      if (!isNaN(val)) {
        this.perPage = val;
        this.currentPage = 1
      } 
    },
    notifyVue(success, message) {
      this.$notify({
          message: message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: success ? "success" : "danger",
          timeout: 5000
        });
      }
  },
  watch: {
    visitor() {
      console.log('---visitor changed');
      this.getConversions()
    }
  }

};
</script>
<style>

</style>
