<template>
  <div class="row">
    <div class="col-12">
      <card :title="table1.title">
        <div class="row justify-content-end px-4">
          <div class="row col-10 justify-content-end">
            <base-button @click="openAddOfferwallModel"  type="primary" fill>New Offerwall</base-button>
          </div>
        </div>

        <add-offerwall
          :show="showAddOfferwallModal"
          :close="closeAddOfferwallModel"
          :save="addOfferwall"
          :model="addOfferwallModel"
          :loading="loads?.createOfferwall"
        >

        </add-offerwall>
        

        <div v-if="selectedItems.length > 0">
          <base-button @click="updateOfferwall(selectedItems, isMajoritySelectedActive)" size="sm"  :type="isMajoritySelectedActive ? 'danger' : 'primary'" fill>{{ isMajoritySelectedActive ? "Disable All" : "Enable all" }}</base-button>
        </div>

        <div class="table-responsive">
          <offerwall-table
            :currentPage="currentPage"
            :perPage="perPage"
            :switchStatus="updateOfferwall"
            :edit="navigateToOfferwall"
            :remove="deleteOfferwall"
            :data="table1.data" 
            :columns="table1.columns"
            thead-classes="text-primary"
            :onSelect="onSelect"
            :selectedItems="selectedItems"
            :loadingItem="loadingItem"
            :loading="loading"
          >
          </offerwall-table>
          <div v-if="table1.data.length >= 1 && !loading">
            <pagination
            :totalItems="table1.data.length"
              :totalPages="totalPages"
              :currentPage="currentPage"
              :onSelect="selectPage"
              :perPage="perPage"
              :onPerPageChange="onPerPageChange"
            ></pagination>
        </div>

        <div
              v-else-if="loading"
              style="height: 40px; width: 100%;"
              class="row justify-content-center align-items-center"
            >
              <clip-loader  color="#C153EC" size="40px" :loading="loading" ></clip-loader>
        </div>
        <div v-else class="row justify-content-center white text-white">
          <span>No results found</span>
        </div>
        </div>
      </card>
    </div>

  </div>
</template>
<script>

import { OfferwallTable } from "@/components";
import { BaseSelect, BaseButton } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from 'vuex';
import Countries from '../assets/data/countries.json'
import AddOfferwall from "./Offerwalls/AddOfferwall.vue";

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

const tableColumns = ["Name", "Status", "Active Offerwalls", "Link", "Created At", "control"];

export default {
  components: {
    OfferwallTable,
    BaseSelect,
    Pagination,
    BaseButton,
    AddOfferwall,
    ClipLoader
  },
  mounted() {
    console.log('---offerwalls', this.offerwalls);
    let countries = Countries.map(x => {
      return {
        title: x.name,
        value: x.code
      }
    })
    this.countryFilter.options = [...this.countryFilter.options, ...countries]
    this.getOfferwalls()
  },
  updated() {
    console.log('---this.offerwalls', this.offerwalls);

  },
  data() {
    return {
      table1: {
        title: "Offerwalls",
        columns: [...tableColumns],
        data: []
      },
      countryFilter: {
        label: "Country",
        name: "country",
        options: [{ title: "ALL", value: "all" }],
      },
      deviceFilter: {
        label: "Device",
        name: "device",
        options: [{ title: "ALL", value: "all" }, { title: "Web", value: "Web" }, { title: "Android", value: "Android" }, { title: "Iphone", value: "iPhone" }],
      },
      country: "all",
      device: "all",
      currentPage: 1,
      perPage: 10,
      selectedItems: [],
      showAddOfferwallModal: false,
      addOfferwallModel: {
        name: null,
        affiliate_id: null
      },
      loading: false,
      loadingItem: null,
      
    };
  },
  computed: {
    totalPages() {
      return Math.round(this.offerwalls.length / this.perPage)
    },
    isMajoritySelectedActive() {
      const item = this.offerwalls.find(x => x.id == this.selectedItems[0])
      if (item && item.active)return true;
      return false
    },
    ...mapGetters([
      'offerwalls',
      'loads'
    ])
  },
  methods: {
    getOfferwalls: async function () {
      this.loading = true;
      await this.$store.dispatch("getOfferwalls", {country: this.country, device: this.device});
      this.table1.data = [...this.offerwalls]
      this.loading = false;
      this.currentPage = 1
    },
    addOfferwall: async function() {
      console.log('----addOfferwall', this.addOfferwallModel);
      const {affiliate_id, name} = this.addOfferwallModel;
      if (!affiliate_id || !name) {
        // show error message
        return;
      }
      this.loading = true;
      await this.$store.dispatch("createOfferwall", this.addOfferwallModel);
      await this.getOfferwalls()
      this.closeAddOfferwallModel()
      this.loading = false;
      this.notifyVue(true, "Successfully created")
    },
    updateOfferwall: async function(ids, status) {
      console.log('----ids', ids, status);
      this.loadingItem = {active: ids[0]};
      await this.$store.dispatch("updateOfferwall", {active: !status, ids});
      // await this.getOfferwalls()
      this.loadingItem = null;
      this.notifyVue(true, "Successfully updated")
    },
    deleteOfferwall: async function(id) {
      this.loadingItem = {delete: id};
      await this.$store.dispatch("deleteOfferwall", id);
      // await this.getOfferwalls()
     this.loadingItem = null;
     this.notifyVue(true, "Successfully removed")

    },
    onFilterChange: function(key, val) {
      this[key] = val;
      this.getOfferwalls()
    },
    selectPage: function (page) {
      if (page > this.totalPages || page < 1) return;
      this.currentPage = page
    },
    onSelect: function (ids, value) {
      if (value) {
        this.selectedItems = [...new Set(this.selectedItems.concat(ids))]
      } else {
        this.selectedItems = []
      }
    },
    openAddOfferwallModel: function () {
      console.log('---this.showAddOfferwallModal', this.showAddOfferwallModal);
      this.showAddOfferwallModal = true;
    },
    closeAddOfferwallModel: function () {
      this.showAddOfferwallModal = false;
    },
    navigateToOfferwall: function (id) {
      this.$router.replace(`/offerwall/${id}`)
    },
    notifyVue(success, message) {
      this.$notify({
          message: message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: success ? "success" : "danger",
          timeout: 5000
        });
    },
    onPerPageChange: function (val) {
      console.log('-onPerPageChange', val)  
      if (!isNaN(val)) {
        this.perPage = val;
        this.currentPage = 1
      } 
    },
  },
  watch: {
    offerwalls(val) {
      this.table1.data = [...val]
    }
  }

};
</script>
<style>

</style>
