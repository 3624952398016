<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div style="width: 100%;">
        <card>
            <div class="row ml-0 mb-4">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a slot="header" class="nav-link title" :class="{ 'active': tab == 'settings' }"
                            @click="setTab('settings')" aria-current="page" href="#">Settings</a>
                    </li>
                    <li class="nav-item">
                        <a slot="header" class="nav-link title" :class="{ 'active': tab == 'theme' }"
                            @click="setTab('theme')" href="#">Theme</a>
                    </li>
                    <li class="nav-item">
                        <a slot="header" class="nav-link title" :class="{ 'active': tab == 'security' }"
                            @click="setTab('security')" href="#">Security</a>
                    </li>
                </ul>
                <!-- <h5 slot="header" class="title">Theme</h5> -->
            </div>
            <div v-if="tab === 'theme'" class="row ml-0 mb-4">
                <ul class="nav nav-pills">
                    <li class="nav-item sub-tab" style="" :class="{ 'sub-tab-active': subTab === 'desktop' }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-laptop" viewBox="0 0 16 16">
                            <path
                                d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                        </svg>
                        <a slot="header" class="nav-link title sub-tab-title" :class="{ 'active': tab == 'desktop' }"
                            @click="setSubTab('desktop')" href="#">Desktop</a>
                    </li>

                    <li class="nav-item sub-tab" :class="{ 'sub-tab-active': subTab === 'mobile' }">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-phone" viewBox="0 0 16 16">
                            <path
                                d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                        <a slot="header" class="nav-link title sub-tab-title" :class="{ 'active': tab == 'mobile' }"
                            @click="setSubTab('mobile')" href="#">Mobile</a>
                    </li>
                </ul>
                <!-- <h5 slot="header" class="title">Theme</h5> -->
            </div>


            <div v-if="tab == 'settings'">
                <div class="row">
                    <div class="col-md-6 px-md-2">
                        <base-input label="Affiliate ID" placeholder="Affiliate ID" v-model="model.affiliate_id">
                        </base-input>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <base-input label="Ogads API Key" placeholder="Ogads API Key" v-model="model.ogads_api_key">
                        </base-input>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 px-md-2">
                        <base-input label="Conversion Rate" placeholder="Conversion Rate" v-model="model.conversion_rate">
                        </base-input>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <base-input label="Payout threshold" placeholder="Payout threshold"
                            v-model="model.payout_threshold">
                        </base-input>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <base-input label="Link alias" placeholder="Link alias" v-model="model.link_alias">
                        </base-input>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <base-input label="Callback URL" placeholder="Callback URL" v-model="model.ogads_postback_url">
                        </base-input>
                    </div>
                    <div class="col-md-6 px-md-2 mb-2">
                            <base-switch :label="'Login With Roblox Username?'" :value="model.auth_roblox_username"
                            :onChange="(val) => onChange(`auth_roblox_username`, val)"></base-switch>
                        </div>
                    <!-- <div class="col-md-6 px-md-2">
                        <base-input label="Rewards Page" placeholder="https://domain.com/rewards" v-model="model.reward_page">
                        </base-input>
                    </div> -->
                </div>

                <div class="row">
                    <div class="col-md-6 px-md-2">
                        <label class="control-label">
                            Desktop Offers Types
                        </label>

                        <div class="row ml-0">
                            <base-checkbox v-for="category in Object.keys(model.desktop_types)" :label="category"
                                v-model="model.desktop_types[category]" inline />
                        </div>
                    </div>

                    <div class="col-md-6 px-md-2">
                        <label class="control-label">
                            Mobile Offers Types
                        </label>

                        <div class="row ml-0">
                            <base-checkbox v-for="category in Object.keys(model.mobile_types)" :label="category"
                                v-model="model.mobile_types[category]" inline />
                        </div>
                    </div>

                    <!-- <div class="col-md-6 px-md-2 mt-3">
                        <label class="control-label">
                            Desktop categories
                        </label>

                        <div class="row ml-0">
                            <base-checkbox v-for="category in Object.keys(model.desktop_categories)" :label="category"
                                v-model="model.desktop_categories[category]" inline />
                        </div>
                    </div> -->
                </div>


            </div>


            <div v-if="tab == 'theme'">
                <h5 class="section-title">General:</h5>
                <div>
                    <div class="row">
                        <div class="col-md-6 px-md-2">
                            <base-input helper-text="Recommended: 1920/1080 px" label="Background Image"
                                placeholder="Image url" v-model="model.settings[subTab].background_image">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].background_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Background Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].background_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>

                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].loading_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Loading Animation Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].loading_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-switch :label="'Show Menu?'" :value="model.settings[subTab].show_menu"
                            :onChange="(val) => onChange(`settings.${subTab}.show_menu`, val)"></base-switch>
                        </div>
                    </div>
                </div>

                <h5 class="section-title">Header:</h5>
                <div>
                    <div class="row">
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Height" placeholder="100px" v-model="model.settings[subTab].header_height">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].header_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Background Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].header_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Background Image" placeholder="https://background.png"
                                v-model="model.settings[subTab].header_background_image">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2">
                            <base-input label="Logo url" placeholder="https://logo.png" v-model="model.settings[subTab].logo">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2">
                            <base-input label="Logo Height" placeholder="50px" v-model="model.settings[subTab].logo_height">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2">
                            <base-input label="Title" placeholder="Title" v-model="model.settings[subTab].title">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].title_text_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Text Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].title_text_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Reward Icon Url" placeholder="https://reward-icon.png" v-model="model.settings[subTab].reward_icon">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Reward Icon Height" placeholder="50px" v-model="model.settings[subTab].reward_icon_height">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Reward Counter Font Size" placeholder="16px" v-model="model.settings[subTab].reward_counter_size">
                            </base-input>
                        </div>
                        <div class="col-md-12 px-md-2 form-group">
                            <label>Description</label>
                            <textarea class="form-control textarea" placeholder="Lorem Ipsum"
                                v-model="model.settings[subTab].description"></textarea>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].description_text_color" style="width: 100%;"
                                swatches="text-advanced">
                                <base-input label="Description Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].description_text_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Description Font Size" placeholder="18px"
                                v-model="model.settings[subTab].description_font_size">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Description Background Image" placeholder="https://background.png"
                                v-model="model.settings[subTab].description_background_image">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-input label="Description Background Image Height" placeholder="100px"
                                v-model="model.settings[subTab].description_background_image_height">
                            </base-input>
                        </div>
                    </div>
                </div>


                <div>
                    <h5 class="section-title">Offer Card:</h5>
                    <div class="row">
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].offer_card_background_color" style="width: 100%;"
                                swatches="text-advanced">
                                <base-input label="Background color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].offer_card_background_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].offer_card_title_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Title color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].offer_card_title_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].offer_card_description_color" style="width: 100%;"
                                swatches="text-advanced">
                                <base-input label="Description color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].offer_card_description_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-switch :label="'Show rate button?'" :value="model.settings[subTab].show_offer_rate_button"
                                :onChange="(val) => onChange(`settings.${subTab}.show_offer_rate_button`, val)"></base-switch>
                        </div>
                        <div class="col-md-6 px-md-2 " v-if="model.settings[subTab].show_offer_rate_button">
                            <base-input label="Rate Button Text" helper-text="Offer rate is showing by default" placeholder="Install"
                                    v-model="model.settings[subTab].rate_button_text">
                                </base-input>
                        </div>
                    </div>
                </div>



                <div>
                    <h5 class="section-title">Button:</h5>
                    <div class="row">
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].button_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].button_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
    
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].button_border_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Border Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].button_border_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].button_text_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Text Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].button_text_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                    </div>
                </div>

                <div>
                    <h5 class="section-title">Sort List:</h5>
                    <div class="row">
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].sort_list_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Title Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].sort_list_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                                v-model="model?.settings[subTab].sort_list_option_color" style="width: 100%;" swatches="text-advanced">
                                <base-input label="Options Color" placeholder="Click to Select Color"
                                    v-model="model?.settings[subTab].sort_list_option_color" slot="trigger">
                                </base-input>
                            </v-swatches>
                        </div>
                        <div class="col-md-6 px-md-2 ">
                            <base-switch :label="'Show Sort List?'" :value="model.settings[subTab].show_sort"
                                :onChange="(val) => onChange(`settings.${subTab}.show_sort`, val)"></base-switch>
                        </div>
                    </div>
                </div>

                <div>
                    <h5 class="section-title">Currency:</h5>
                    <div class="row">
                        <div class="col-md-6 px-md-2">
                            <base-input helper-text="Recommended: 32/32 px" label="Icon Url" placeholder="https://icon.png"
                                v-model="model?.settings[subTab].coin_icon">
                            </base-input>
                        </div>
                        <div class="col-md-6 px-md-2">
                            <base-input label="Name" placeholder="Name" v-model="model?.settings[subTab].coin_name">
                            </base-input>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="tab == 'security'">
                <div class="row" v-if="model.security">
                    <div class="col-md-6 px-md-2">
                        <base-switch :label="'Allow access from bad IPs?'" :value="model.security.bad_ip"
                            :onChange="(val) => onChange('security.bad_ip', val)"></base-switch>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <base-switch :label="'Allow access from bad ISPs?'" :value="model.security.bad_isp"
                            :onChange="(val) => onChange('security.bad_isp', val)"></base-switch>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <base-switch :label="'Allow users to change their country?'" :value="model.security.change_country"
                            :onChange="(val) => onChange('security.change_country', val)"></base-switch>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <base-switch :label="'Allow Ad blockers?'" :value="model.security.adblocker"
                            :onChange="(val) => onChange('security.adblocker', val)"></base-switch>
                    </div>
                    <div class="col-md-6 px-md-2">
                        <!-- <base-switch :label="'Allow access from IPs with lower trust score?'" :value="model.security.ip_score" :onChange="(val) => onChange('security.ip_score', val)"></base-switch> -->
                        <label class="control-label mb-2" style="font-size: 13px;">
                            {{ 'Allow access from IPs with a fraud score less than or equal to:' }}
                        </label>
                        <base-input placeholder="30" type="number" v-model="model.security.ip_score">
                        </base-input>
                    </div>
                </div>

            </div>

            <h5 class=" text-danger mt-4" style="font-size: 1em;" v-if="error">{{ error }}</h5>


            <base-button @click="updateUser" :class="{'button-loading': formLoading}"  slot="footer" type="primary" fill>
                <clip-loader v-if="formLoading" color="white" size="25px" :loading="formLoading" ></clip-loader>
                <span v-else="">Save</span>
            </base-button>
        </card>
    </div>
</template>
<script>

import { BaseAlert, BaseSwitch, BaseInput } from '@/components';
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import "./index.css"
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
    components: {
        BaseAlert,
        VSwatches,
        BaseSwitch,
        ClipLoader
    },
    updated() {
        console.log('-----edtipor', this.error);
    },
    data: function () {
        return {
            color: "#ffffff",
            colors: '#194D33A8', // etc: { h: 150, s: 0.66, v: 0.30 }, { r: 255, g: 0, b: 0 }, '#194d33'
            categories: ["cpi", "cpa", "pin", "cpe", "surveys"],
            tab: "settings",
            subTab: "desktop"
        }
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {};
            }
        },
        success: {
            type: Boolean,
            default: false
        },
        view: {
            type: String,
        },
        formLoading: {
            type: Boolean,
            default: false,
        },
        updateUser: {
            type: Function,
            default: () => { }
        },
        onChange: {
            type: Function,
            default: () => { }
        },
        error: {
            type: String
        },
        setDevice: Function,
        device: String,
    },
    methods: {
        setTab(tab) {
            this.tab = tab
        },
        setSubTab(subTab) {
            this.subTab = subTab
            this.setDevice(subTab)
        },
        getThemeSetting(type, key) {
            return this.model.settings?.theme?.[type]?.[key]
        },
        changed(val) {
            console.log('-----changed;', val);
        }
    },
    watch: {
        error() {
            console.log('----- watch error', this.error);
        }
    }
};
</script>
<style></style>
  