<template>
  <card type="offerwall">
    <h5 slot="header" class="title">User Details</h5>
    <div class="">
      <label class="control-label">User ID:</label>
      <span class="ml-2">{{ user?.user_id }}</span>
    </div>
    <div class="mt-3">
      <label class="control-label">IP:</label>
      <span class="ml-2">{{ user?.ip }}</span>
    </div>
    <div class="mt-3">
      <label class="control-label">Country:</label>
      <span class="ml-2">{{ user?.country }}</span>  
    </div>
    <div class="mt-3">
      <label class="control-label">Device:</label>
      <span class="ml-2">{{ user?.device }}</span>
    </div>
    <div class="mt-3">
      <label class="control-label">Status:</label>
      <span class="ml-2">{{ user?.blocked ? "Blocked" : "Active" }}</span>
    </div>
    <div class="mt-3">
      <label class="control-label">Coins:</label>
      <span class="ml-2">{{ user?.coins || 0 }}</span>
    </div>
    <div class="mt-3" v-if="user?.roblox_username">
      <label class="control-label">Roblox Username:</label>
      <span class="ml-2">{{ user?.roblox_username }}</span>
    </div>
    <div class="mt-3">
      <label class="control-label">Last visit:</label>
      <span class="ml-2">{{ lastVisit }}</span>
    </div>

  </card>
</template>
<script>
import moment from 'moment';

  export default {
    updated() {
    // console.log('-----', this.offerwall);
  },
  created() {
    // console.log('----- offerwall', this.offerwall);
  },
  computed: {
    lastVisit() {
      return this.user?.last_visit ? moment(this.user.last_visit).format("YYYY-MM-DD HH:mm:ss") : ""
    }
  },
    props: {
      user: {
        type: Object
      }
    },
    methods: {

    }
  }
</script>
<style>
</style>
