<template>
  <div class="row">
    <div class="col-12">
      <card :title="table1.title">
        <div class="row justify-content-start px-2">
          <div class="col-12 justify-content-start filters ">
            <base-select :source="'offer'" :onChange="(val) => onFilterChange('offerwall', val)" :value="offerwall"
              :label="offerwallFilter.label" class="pr-2  col-md-3 w-full" :options="offerwallFilter.options"
              :default="offerwall"></base-select>
            <base-select :source="'offer'" :onChange="(val) => onFilterChange('country', val)" :value="country" :label="countryFilter.label"
              class="pr-2  col-md-3 w-full" :options="countryFilter.options" :default="country"></base-select>
            <base-select :source="'offer'" :onChange="(val) => onFilterChange('device', val)" :value="device" :label="deviceFilter.label"
              class="pr-2  col-md-3 w-full" :options="deviceFilter.options" :default="device"></base-select>
            <base-select :source="'offer'" :onChange="(val) => onFilterChange('category', val)" :value="category"
              :label="categoryFilter.label" class="pr-2  col-md-3 w-full" :options="categoryFilter.options"
              :default="category"></base-select>
          </div>
        </div>
        <div class="row justify-content-between mt-4 px-4">
          <div class="row col-7 col-md-6 justify-content-start">
            <base-input :onChange="(val) => onSearchChange(val)" :model="search" :placeholder="'Search ...'"
              class="mr-2  searchFilter" :options="countryFilter.options" :value="search"></base-input>
          </div>
          <div class="row col-5  col-md-6 justify-content-end">
            <base-button @click="clearFilters" size="sm" :type="'primary'" fill>Clear filters</base-button>
          </div>
        </div>

        <div v-if="selectedItems.length > 0">
          <base-button @click="updateOffer(selectedItems, isMajoritySelectedActive)" size="sm"
            :type="isMajoritySelectedActive ? 'danger' : 'primary'" fill>{{ isMajoritySelectedActive ? "Disable All" :
              "Enable all" }}</base-button>
        </div>

        <div class="table-responsive">
          <offer-table :loading="loading" :currentPage="currentPage" :perPage="perPage" :switchStatus="updateOffer" :data="table1.data"
            :columns="table1.columns" thead-classes="text-primary" :onSelect="onSelect" :selectedItems="selectedItems" 
            :pinOffer="pinOffer" :loadingItem="loadingItem"
            >
          </offer-table>
          <div v-if="table1.data.length >= 1 && !loading">
            <pagination :totalItems="table1.data.length" :totalPages="totalPages" :currentPage="currentPage"
              :onSelect="selectPage" :perPage="perPage" :onPerPageChange="onPerPageChange"></pagination>
          </div>

          <div v-else-if="loading" style="height: 40px; width: 100%;" class="row justify-content-center align-items-center">
            <clip-loader color="#C153EC" size="40px" :loading="loading"></clip-loader>
          </div>
          <div v-else class="row justify-content-center">
            <span>No results found</span>
          </div>
        </div>
      </card>
    </div>

  </div>
</template>
<script>

import { OfferTable } from "@/components";
import { BaseSelect } from "@/components";
import { BaseInput } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from 'vuex';
import Countries from '../assets/data/countries.json'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

const tableColumns = ["select", "Offer_id", "Icon", "Name", "Category", "Countries", "Devices", "Payout", "Status",  "control"];

export default {
  components: {
    OfferTable,
    BaseSelect,
    BaseInput,
    Pagination,
    ClipLoader
  },
  mounted() {
    let countries = Countries.map(x => {
      return {
        title: x.name,
        value: x.code
      }
    })
    this.countryFilter.options = [...this.countryFilter.options, ...countries]
    this.getOfferwalls()
    // console.log('-----finished');
  },
  updated() {
    // console.log('---this.', this.offerwall, this.offerwallFilter);
  },
  data() {
    return {
      table1: {
        title: "Offers",
        columns: [...tableColumns],
        data: []
      },
      countryFilter: {
        label: "Country",
        name: "country",
        options: [{ title: "ALL", value: "all" }],
      },
      deviceFilter: {
        label: "Device",
        name: "device",
        options: [{ title: "ALL", value: "all" }, { title: "Desktop", value: "Desktop" }, { title: "Android", value: "Android" }, { title: "Iphone", value: "iPhone" }],
      },
      categoryFilter: {
        label: "Category",
        name: "category",
        options: [{ title: "ALL", value: "all" }, { title: "CPI", value: "cpi" }, { title: "CPA", value: "cpa" }, {title: "CPE", value: "cpe"}, { title: "PIN", value: "pin" }],
      },
      offerwallFilter: {
        label: "Offerwall",
        name: "offerwall",
        options: [],
      },
      country: "all",
      device: "all",
      offerwall: null,
      search: null,
      category: "all",
      currentPage: 1,
      perPage: 10,
      selectedItems: [],
      loading: false,
      initialized: false,
      loadingItem: null,
      statusLoadingItem: null
      // pinnedOffers: []

    };
  },
  computed: {
    totalPages() {
      return Math.round(this.filteredOffers.length / this.perPage)
    },
    filteredOffers() {
      // if(isNaN(parseInt(this.search))) {
      //   return this.offers.filter(x => x.offer_id.includes(this.search))
      // }
      if (this.search) {
        const value = this.search.toLowerCase().trim()
        let arr = this.offers.filter(x => x.name?.toLowerCase()?.includes(value) || x.offer_id?.toLowerCase().includes(value))
      // console.log('---filtered offers', arr, this.offers);

      return arr;
      }

      return this.offers
    },
    isMajoritySelectedActive() {
      const item = this.filteredOffers.find(x => x.id == this.selectedItems[0])
      if (item && item.active) return true;
      return false
    },
    ...mapGetters([
      'offers',
      'offerwalls'
    ])
  },
  methods: {
    getOffers: async function (initialize) {
      // console.log('---this.loading', this.loading);
      if (this.loading) return;
      this.loading = true;
      const search = this.search;
      await this.$store.dispatch("getOffers", { country: this.country, device: this.device, offerwall_id: this.offerwall, search, category: this.category });
      this.mapOffers(search, initialize)
    },
    mapOffers: function (search, initialize) {
      // console.log('---mapOffers', search, initialize);
      // console.log('---this.offers', this.offers);
      this.table1.data = [...this.filteredOffers]
      this.loading = false;
      this.currentPage = 1
      if (initialize) this.initialized = initialize
      if (search != this.search) {
        this.getOffers()
      }
    },
    getOfferwalls: async function () {
      this.loading = true;
      await this.$store.dispatch("getOfferwalls");
      this.loading = false;
      this.offerwallFilter.options = this.offerwalls.map(x => ({ title: x.name, value: x._id }));
      // console.log('----this.offerwallFilter', this.offerwallFilter.options);
      // console.log('getOfferwalls', this.offerwall);
      if (this.offerwall && this.offerwall !== "all") {
        const item = this.offerwallFilter.options.find(x => x.value == this.offerwall);
        // console.log('----item', item);
        if (item) this.offerwall = item
      } else {
        this.offerwall = this.offerwallFilter.options[0]?.value
      }
      // this.pinnedOffers = this.offerwalls.find(x =>x._id == this.offerwall)?.pinned_offers

      // console.log('--this.pinned_offers', this.pinned_offers);
      this.getOffers(true)
    },
    onFilterChange: function (key, val) {
      if (key == 'search') return
      this[key] = val;
      // console.log('----onFilterChange', key, val);
      if (this.offerwall && this.initialized) {
        this.getOffers()
      }
    },
    updateOffer: async function (ids, status) {
      // console.log('--this.offerwall', this.offerwall);
      
      if (ids.length == 1) {
        this.loadingItem = {"active": ids[0]}
      } else {
        this.loading = true;
      }

      await this.$store.dispatch("updateOffer", { active: !status, ids, offerwall_id: this.offerwall });
      // console.log('---this.initialized after', this.initialized);
      this.loading = false;
      this.loadingItem = null

    },
    pinOffer: async function (ids, status) {

      if (ids.length == 1) {
        this.loadingItem = {"pinned": ids[0]}
      } else {
        this.loading = true;
      }
      await this.$store.dispatch("updateOffer", { pin: !status, ids, offerwall_id: this.offerwall }); 
      this.loadingItem = null
      this.loading = false;

      if (this.initialized) {
        // const search = this.search;
        // await this.$store.dispatch("getOffers", { country: this.country, device: this.device, offerwall_id: this.offerwall, search, category: this.category });
        // this.mapOffers(search, undefined)
      }

    },
    selectPage: function (page) {
      if (page > this.totalPages || page < 1) return;
      this.currentPage = page
    },
    onSelect: function (ids, value) {
      if (value) {
        this.selectedItems = [...new Set(this.selectedItems.concat(ids))]
      } else {
        this.selectedItems = []
      }
    },
    onPerPageChange: function (val) {
      // console.log('-onPerPageChange', val)  
      if (!isNaN(val)) {
        // console.log('-onPerPageChange inside', val)  
        this.perPage = val;
        this.currentPage = 1
      } 
    },
    clearFilters: function () {
      // console.log('---clear filters');
      this.device = "all";
      this.country = "all";
      this.offerwall = this.offerwallFilter.options[0]?.value;
      if(this.initialized)
      this.getOffers()
    },
    onSearchChange: function (val) {
      this.search = val      
    }
  },
  watch: {
    filteredOffers() {
      this.table1.data = [...this.filteredOffers]
    }
  }

};
</script>
<style></style>
