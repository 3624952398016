<template>
  <div class="row">
    <div class="col-md-6">
      <settings view="offerwall" :device="device" :setDevice="setDevice" :error="errors.offerwall" :onChange="onChange" :model="model" :success="success" :formLoading="loads?.updateOfferwall" :updateUser="updateOfferwall" >
      </settings>
    </div>
    <div class="col-md-6">
      <user-card  :offerwall="offerwall"></user-card>
      <base-button @click="reloadPreview()" size="sm" slot="footer" type="primary" style="float: right;" fill>Reload</base-button>

      <preview :device="device"   :link="offerwallLink"></preview>
    </div>
  </div>
</template>
<script>
  import Settings from './Offerwall/Settings';
  import UserCard from './Profile/UserCard'
  import {Preview} from '@/components'
  import { mapGetters } from 'vuex'

  export default {
    components: {
      Settings,
      UserCard,
      Preview
    },
    data() {
      return {
        model: {
          settings: {},
          affiliate_id: null,
          payout_threshold: null,
          domain: null,
        },
        offerwallLinkRefreshor: 0,
        loading: false,
        formLoading: false,
        success: false,
        device: "desktop"
      }
    },
    computed: {
      ...mapGetters([
        'offerwall',
        'errors',
        'successMessages',
        'loads'
      ]),
      errorMessage() {
        return this.errors.offerwall
      },
      offerwallLink() {
        return this.offerwall.link + ( this.offerwall.link?.includes("?") ? '&' : '?' ) + `&refreshor= + ${this.offerwallLinkRefreshor}&screen=${this.device}`
      }
    },
    updated() {
      console.log('---offerwall updated', this.errors.offerwall);
      if (this.model === null) this.model = this.offerwall
    },
    mounted() {
      this.getOfferwall()

    },
    methods: {
      getOfferwall: async function () {
        this.loading = true
        await this.$store.dispatch("getOfferwall", this.$route.params.id);
        this.loading = false
      },
      updateOfferwall: async function() {
        this.formLoading = true;
        await this.$store.dispatch("updateOfferwall", {ids: [this.$route.params.id], ...this.model});
        this.offerwallLinkRefreshor += 1
        await this.getOfferwall()
        this.formLoading = false;
        // console.log('----this.success.offerwall', this.successMessages);
        if (this.successMessages.offerwall) {
          this.notifyVue(true, this.successMessages.offerwall)
        }
      },
      notifyVue(success, message) {
        this.$notify({
          message,
          icon: "tim-icons icon-bell-55",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: success ? "success" : "danger",
          timeout: 5000
        });
      },
      onChange(key, val) {
        console.log('----onChange key, value', key, val);
        if (key.includes('settings')) {
          const parts = key.split(".");
          this.model.settings = {
              ...this.model.settings,
              [`${parts[1]}`]: {
                ...this.model.settings[parts[1]],
                [`${parts[2]}`]: val
              }
          }
          return;
        }
        if (key.includes('security')) {
          const parts = key.split(".");
          console.log('---parts');
          this.model.security = {
              ...this.model.security,
              [`${parts[1]}`]: val
          }
          return;
        }
        this.model[`${key}`] = val
        console.log('---this.model', this.model, val);
      },
      reloadPreview() {
        this.offerwallLinkRefreshor += 1
      },
      setDevice(val) {
        this.device = val
      }
    },
    watch: {
      offerwall(val) {
        console.log('-----valj', val);
        this.model = val
      }
    }
  }
</script>
<style>
</style>
