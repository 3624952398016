<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="row">
        <modal :show="show" id="searchModal"  :centered="false" :show-close="true">
            <div class="row px-md-2" :style="{'background-color': theme == 'black' ? '#28293D' : 'white'}">
                <h4 style="font-weight: bold; padding-left: 15px;">New Offerwall</h4>
                <div class="col-md-12">
                    <base-input label="Offerwall Name" type="text" placeholder="Name" v-model="model.name">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Affiliate ID" placeholder="Affiliate ID" v-model="model.affiliate_id">
                    </base-input>
                </div>
                <div class="col-md-12">
                    <base-input label="Ogads API Key" placeholder="Ogads API Key" v-model="model.ogads_api_key">
                    </base-input>
                </div>
                <div
                    class="px-3 mt-4"
                >
                    <base-button class="mr-3" :class="{'button-loading': loading}" :disabled="!model.name || !model.affiliate_id || !model.ogads_api_key || loading" @click="save" slot="footer" type="primary" fill>
                        <clip-loader v-if="loading" color="white" size="25px" :loading="loading" ></clip-loader>
                        <span v-else="">Save</span>
                    </base-button>

                    <base-button :disabled="loading" @click="close" slot="footer" type="danger" fill>Cancel</base-button>

                </div>

            </div>
        </modal>


    </div>
</template>
<script>
import Modal from '@/components/Modal';

import { BaseAlert } from '@/components';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
    components: {
        BaseAlert,
        Modal,
        ClipLoader
    },
    created() {
        console.log('-----edtipor', this.model, this.view);
    },
    data() {
        return {
        theme: localStorage.getItem("theme")
        }
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {};
            }
        },
        show: {
            type: Boolean,
            default: false
        },
        save: {
            type: Function,
        },
        close: {
            type: Function
        },
        loading: Boolean
    }
};
</script>
<style>

</style>
